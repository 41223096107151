<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="12"
                md="12"
                sm="12">
                <h3>Change Password</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>
        
        <v-row>
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <label>Your current password</label>
                <v-text-field
                    :append-icon="showPasswordToday ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPasswordToday ? 'text' : 'password'"
                    v-model="passwordToday"
                    autocomplete="new-password"
                    hint="Current Password"
                    :rules=[validations.required]
                    single-line
                    outlined
                    @click:append="showPasswordToday = !showPasswordToday"
                    dense
                >
                    <template v-slot:progress>
                        <v-progress-linear
                            :value="progress"
                            :color="color"
                            absolute
                            height="7"
                        >
                        </v-progress-linear>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="6"
                md="12"
                sm="12"
            >
                <label>Your new password</label>
                <v-text-field
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    autocomplete="new-password"
                    :rules=[validations.password]
                    hint="Password must be at least 8 characters, 1 uppercase and 1 lowercase letter"
                    single-line
                    outlined
                    loading
                    @click:append="showPassword = !showPassword"
                    dense
                >
                    <template v-slot:progress>
                        <v-progress-linear
                            :value="progress"
                            :color="color"
                            absolute
                            height="7"
                        >
                        </v-progress-linear>
                    </template>
                </v-text-field>
            </v-col>
            <v-col 
                cols="12"
                lg="6"
                md="12"
                sm="12"
            >
                <label>Confirm your new password</label>
                <v-text-field
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    v-model="confirmPassword"
                    autocomplete="new-password"
                    :rules=[validations.samePassword(password,confirmPassword)]
                    single-line
                    outlined
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    dense
                ></v-text-field>
            </v-col>
        </v-row>

        <br />

        <ActionButtons
            :request="userLoggedGetters"
            :showButtons="{
                cancel: false,
                save: true
            }"
            @save="saveNewPassword"
        />

        <DialogMessage 
            v-on:methodOKToCall="dialogMessage.methodOK"
            :showDialogMessage.sync="dialogMessage.show"
            :headerTitle="dialogMessage.headerText"
            :bodyText="dialogMessage.bodyText"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required, validPassword } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import DialogMessage from "@/components/Layout/DialogMessage";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            DialogMessage
        },

        data: () => ({

            loading: false,
            passwordToday: "",
            password: "",
            confirmPassword: "",
            showPasswordToday: false,
            showPassword: false,
            showConfirmPassword: false,

            validations: {
                required: required,
                password: validPassword,
                samePassword (password, confirmPassword) {
                    if (password !== confirmPassword) {
                        return "Passwords not matched!"
                    }
                    else
                        return true;
                }
            },

            dialogMessage: {
                show: false,
                headerText: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            progress () {
                return Math.min(100, this.password.length * 13)
            },

            color () {
                return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
            },
        },

        methods: {

            validForm() {

                var isValidPass = true;

                if (this.password != "" || this.confirmPassword != "")
                {
                    isValidPass = validPassword(this.password) === true && this.password === this.confirmPassword;
                }

                var isValidFrm = isValidPass === true;

                return isValidFrm;
            },

            async saveNewPassword() {

                if (this.validForm() === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    let requestProfile = {
                        idUser: this.userLoggedGetters.id, 
                        currentPassword: this.passwordToday,
                        password: this.password,
                        confirmPassword: this.confirmPassword
                    }

                    const result = await this.$store.dispatch("userModule/ChangePassword", requestProfile);

                    if (result.success === true) {

                        if (this.userLoggedGetters.profile.validatePasswordChange === 1) {

                            let message = "You will be receiving an e-mail with further instructions to reset your password after your request is approved by managament.";

                            this.dialogMessage = {
                                show: true,
                                headerText: 'Success',
                                bodyText: message,
                                methodOK: (() => { this.$router.push({ path: "/" }); }),
                            };
                        }
                        else {
                            this.showToast("success", "Success!", result.message);
                            this.$router.push({ path: "/" });
                        }
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },
        },
    })
</script>
